import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments"
export default class extends Controller {
  static targets = ["container", "form"]
  static values = { url: String }
  page = 1
  connect() {
    this.scrollToBottom();
    document.addEventListener('turbo:submit-end', (e) => {
      if (e.detail.success && e.detail.formSubmission.submitter.formMethod != 'get') {
        this.resetForm();
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      }
    });
  }

  resetForm() {
    this.formTarget.reset();
  }

  scrollToBottom() {
    this.containerTarget.scrollTop = this.containerTarget.scrollHeight;
  }
}
